<template>
  <TagbyDetailLayout :hasLoadError="state.hasLoadError">
    <template #content>
      <TagbyTabs :tabList="tabList">
        <template #tab(default)>
          <DefaultCard />
          <PromotionModalCard />
        </template>
      </TagbyTabs>
    </template>

    <template #side>
      <ActionCard />
      <TagbyTimelinesV3
        v-if="!getters.isCreatingMode"
        :timelineList="state.timelineList"
      />
    </template>

    <template #hidden>
      <ErrorReasonModal />
      <DeleteItemModal />
      <CreateMemoSidebar />
    </template>
  </TagbyDetailLayout>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { getRouterIdx } from '@/utils/router'
import TagbyTabs from '@/components/TagbyTabs.vue'
import TagbyDetailLayout from '@/components/TagbyDetailLayout.vue'
import TagbyTimelinesV3 from '@/components/TagbyTimelinesV3.vue'
import ActionCard from '../components/ActionCard/Main.vue'
import useFetch from '../useFetch'
import ErrorReasonModal from '../components/Modals/ErrorReasonModal.vue'
import DeleteItemModal from '../components/Modals/DeleteItemModal.vue'
import CreateMemoSidebar from '../components/Sidebars/CreateMemoSidebar.vue'
import state from '../state'
import getters from '../getters'
import useState from '../useState'
import useAlert from '../useAlert'
import DefaultCard from '../components/DefaultCard/Main.vue'
import PromotionModalCard from '../components/PromotionModalCard/Main.vue'

export default defineComponent({
  components: {
    TagbyDetailLayout,
    TagbyTabs,
    ActionCard,
    TagbyTimelinesV3,
    ErrorReasonModal,
    DeleteItemModal,
    CreateMemoSidebar,
    DefaultCard,
    PromotionModalCard,
  },
  setup() {
    const {
      resetState,
    } = useState()
    resetState()

    state.idx = getRouterIdx()
    state.type = 'PROMOTION_MODAL'

    const {
      getAdminMe,
      getTimelineList,
    } = useFetch()

    const {
      getWidget,
    } = useAlert()

    if (!getters.isCreatingMode) {
      getAdminMe()
      getWidget()
      getTimelineList()
    }

    const tabList = [
      { key: 'default', title: '정보' },
    ]

    return {
      tabList,
      state,
      getters,
    }
  },
})
</script>
