<template>
  <BCard>
    <AppCollapse>
      <AppCollapseItem
        v-for="item in state.content"
        :key="item.id"
        :title="getTitle(item)"
      >
        <BRow>
          <BCol
            cols="12"
            sm="6"
            md="4"
          >
            <BFormGroup
              label="TYPE"
            >
              <TagbySelect
                v-model="item.type"
                :options="typeOptions"
              />
            </BFormGroup>
          </BCol>

          <BCol
            cols="12"
            sm="6"
            md="4"
          >
            <BFormGroup
              label="BUTTON_TEXT"
            >
              <BFormInput
                v-model="item.button"
                placeholder="확인"
              />
            </BFormGroup>
          </BCol>

          <BCol
            cols="12"
            sm="6"
            md="4"
          >
            <BFormGroup
              label="REDIRECT_PATH"
            >
              <TagbySelect
                v-model="item.redirect_option"
                :reduce="opt => opt.value"
                :options="redirectOptions"
                @input="inputRedirectPath(item.uuid)"
              />
            </BFormGroup>
          </BCol>

          <BCol
            cols="12"
            sm="6"
            md="4"
          >
            <BFormGroup
              v-if="item.redirect_option === 'link'"
              label="LINK"
            >
              <BFormInput
                v-model="item.option_text"
                placeholder="https://"
                trim
              />
            </BFormGroup>
            <BFormGroup
              v-if="item.redirect_option === '/campaign'"
              label="CAMPAIGN_IDX"
            >
              <BFormInput
                v-model="item.option_text"
                placeholder="ex) 432"
                trim
                number
                type="number"
              />
            </BFormGroup>
            <BFormGroup
              v-if="item.redirect_option === '/market'"
              label="PRODUCT_NO"
            >
              <BFormInput
                v-model="item.option_text"
                placeholder="ex) P20231205034817T23R1"
                trim
              />
            </BFormGroup>
          </BCol>

          <BCol
            cols="12"
            sm="6"
            md="4"
          >
            <BFormGroup
              label="STATE"
            >
              <TagbySelect
                v-model="item.state"
                :options="stateOptions"
              />
            </BFormGroup>
          </BCol>

          <BCol
            cols="12"
            sm="6"
            md="4"
          >
            <BFormGroup
              label="START_AT"
            >
              <TagbyDatetimeInputV3 v-model="item.start_at" />
            </BFormGroup>
          </BCol>

          <BCol
            cols="12"
            sm="6"
            md="4"
          >
            <BFormGroup
              label="END_AT"
            >
              <TagbyDatetimeInputV3 v-model="item.end_at" />
            </BFormGroup>
          </BCol>

          <BCol
            cols="12"
          >
            <BFormGroup
              label="CONTENT"
            >
              <BFormFile
                @input="inputImgFile(item.uuid, $event)"
              />
            </BFormGroup>
          </BCol>

          <BCol
            v-if="item.content != null"
            cols="12"
          >
            <BImg
              :src="item.content"
              fluid
            />
          </BCol>

        </BRow>

        <BRow class="d-flex justify-content-end">
          <BButton
            class="mr-1"
            variant="outline-danger"
            @click="turnOnDeleteModal(item.uuid)"
          >
            삭제
          </BButton>
        </BRow>
      </AppCollapseItem>
    </AppCollapse>

  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BFormCheckbox,
  BImg,
  BFormFile,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import TagbyDatetimeInputV3 from '@/components/TagbyDatetimeInputV3.vue'
import TagbySelect from '@/components/TagbySelect.vue'
import state from '../../state'
import getters from '../../getters'
import useDefault from '../../promotion-modal/useDefault'
import useDeleteModal from '../../useDeleteModal'
import TagbyRangeInputV2 from '@/components/TagbyRangeInputV2.vue'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BFormCheckbox,
    BImg,
    BFormFile,
    TagbyDatetimeInputV3,
    TagbySelect,
    TagbyRangeInputV2,
    AppCollapse,
    AppCollapseItem,
  },
  setup() {
    const stateOptions = [
      'ACTIVE',
      'INACTIVE',
    ]

    const {
      getTitle,
      inputRedirectPath,
      inputImgFile,
    } = useDefault()

    const {
      turnOnModal: turnOnDeleteModal,
    } = useDeleteModal()

    const typeOptions = [
      'FULL_SCREEN',
      'SINGLE_POPUP',
      'BOTTOM_SHEET',
    ]

    const redirectOptions = [
      { label: '홈', value: '/main/home' },
      { label: '링크', value: 'link' },
      { label: '캠페인 상세', value: '/campaign' },
      { label: '추천 캠페인', value: '/campaigns/recommend' },
      { label: '최근 등록 상품', value: '/products/recent' },
      { label: '상품 상세', value: '/market' },
    ]

    return {
      state,
      getters,
      typeOptions,
      stateOptions,
      getTitle,
      turnOnDeleteModal,
      redirectOptions,
      inputRedirectPath,
      inputImgFile,
    }
  },
})
</script>
